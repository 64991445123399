import React from "react";
import { SmartPayProfile } from "../../components/SmartPay/Profile";
import { useParams } from "react-router-dom";

export default function SmartPayProfilePage() {
  const { checkoutToken } = useParams();

  const handleError = async (data: any) => {
    window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://profile_redirect?result=error&errorCode=${data}`;
  }

  const handleSuccess = async (data: any) => {
    window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://profile_redirect?result=success&paymentProfileId=${data?.storedPaymentOptionReference}`;
  }

  const sdkUrl = 'https://sdk.cons.smartpay.jpmmps.com/light-sdk/smp-bundle.js'
  //const sdkUrl = 'https://sdk.cons.smartpay.jpmmps.com/smp-bundle.js'

  return (
    <div>
      <SmartPayProfile
        checkoutToken={checkoutToken}
        onError={handleError}
        onSuccess={handleSuccess}
        sdkUrl={sdkUrl}
      />
    </div>
  )
}
